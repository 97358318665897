
* {
  font-family: Montserrat;
}

#img-modal .modal-content {
  background-color: transparent;
}

#img-modal .close {
  color: white;
}

.dot {
  height: 7px;
  width: 7px;
  background-color: #579264;
  border-radius: 50%;
  display: inline-block;
}

.search-styles {
  background-color: "white";
  color: "#999999";
  border: "1px solid #999999";
  border-radius: 7;
}

.nav-text {
  display: block;
  line-height: 1.125rem;
  font-size: 0.8rem;
  padding: 0.2rem 0!important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E9E9E9; 
}

/* Login */
.bg-gradient {
  background-image: linear-gradient(to right, rgba(189, 189, 189, 0.507) , rgba(242, 242, 242, 0.801));
  height: 100%;
}

.bg-transparent {
  background-color: transparent!important;
}
.text-kr {
  color: #579264 ;
}
.kr-color {
  background-color: #579264 ;
}

.bg-sidebar {
  background-image: url("../img/bg-sidebar.png")!important;
  background-size: cover;
  background-position: inherit;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-body {
  /* background-image: url("../../../src/assets/img/bg-body.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.login-panel {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #cccccc;
  width: 100%;
  height: 100vh;
}

.event-description {
  color: rgba(124, 122, 122, 0.87);
  letter-spacing: 0.20px;
  font-weight: 300;
}

.detail-box {
  background: #F4F2F2;
  border-radius: 10px;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .7em;
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
}

.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
  padding-left: 20px;
}

.avatar {
  margin-left: -25px;
  position: relative;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.avatar img {
  width: 40px;
  height: 40px;
}

.DateRangePickerInput__withBorder {
  border-radius: 10px;
  border: none;
  width: 100%;
  background-color: transparent;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* .DateRangePickerInput_arrow {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  color: #484848;
} */

.DateInput_input, .DateInput_input__focused {
  width: 100%;
  background-color:  rgb(255, 255, 255) !important;
  font-size: 0.9em;
  border-radius: 10px;
}

.circle-dot {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.54);
}

.field-box {
  background-color: rgba(232, 232, 232, 0.67) !important;
}

.field-box-add {
  background-color: transparent !important;
}

.form-control-add {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-width: 1px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 0;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}

.shadow:hover {
  box-shadow: 2px 2px 10px 2px rgba(173,173,173,1)!important;
  transition: 0.5s;
}

.status-event1 {
  cursor: pointer;
  margin: 0;
  font-weight: bold;
  color: rgba(56, 163, 60, 0.87);
}

.status-event2 {
  cursor: pointer;
  margin: 0;
  font-weight: bold;
  color: #D4252A;
}

.status-event3 {
  cursor: pointer;
  margin: 0;
  font-weight: bold;
  color: rgba(124, 122, 122, 0.87);
}

.box-dotted-spacing {
  width: 100%;
  height: 200px;
  border: 1px dotted grey
}

.DateInput {
  border-radius: 10px;
  /* width: 100%; */
  box-shadow: 1px 1px 1px rgba(189, 189, 189, 0.507);
}

.plant-detail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 575.98px) { 
  .container-sidebar {
    margin-left: 20px;
    margin-right: 20px;
  }
  .spacing-resp {
    padding: 1rem!important;
  }
}

@media (width: 768px) { 
  .container-sidebar {
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
  .plant-detail {
    width: 100%;
    height: 600px;
  }
}

.calendar-custom {
  border: none;
  width: 100%;
}

.react-calendar__tile--active {
  background:#3B7CFF;
  color: white;
}

abbr[title] {
  border-bottom: none;
  /* text-decoration: underline; */
  text-decoration: underline dotted;
  color: #7C86A2!important;
  font-weight: bolder;
}

.box-manage-document {
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.box-manage-document:hover {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.btn-add {
  width: 100%;
  font-size: 0.8em; 
  font-weight: 400; border-radius: 0;
  color: white;
  background-color: #D4252A;
  cursor: pointer;
}

/* .app-body, .app-footer {
  background-color: "#F9FAFC !important";
} */

.dropzone {
  border: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background: white;
}

.SingleDatePicker {
  position: relative;
  width: 100%!important;
  display: block;
}

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff;
  /* width: 100%; */
}

/* .nav-active-border .nav-link.active {
  border-bottom: solid 1px #579264
} */
.nav-link {
  border: 0;
}

.nav-active-border .nav-link:hover, 
.nav-active-border .nav-link.active {
  border-bottom: solid 1px #579264;
}

.box-region {
  background: #D7CABF;
  box-shadow: 0px 2px 4px #EFF2F7;
  border-radius: 10px; 
  color: white;
}

.box-title { 
  display: flex;
  font-weight: bold; 
  justify-content: center; 
  align-items: center;
}

.nav-active {
  background-color: #579264;
  color: white;
}

.pagination .page-item.active {
  background-color: rgb(87, 146, 100) !important;
  border-color: rgb(87, 146, 100) !important;
}

.btn-table:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
